import { getFormConfig } from './formDataComfig';
import { tab_zh } from '../../../configs/tabTranslate';
import {isCanShowBlock} from "../../../../../../utils";

export const formConfig = vm => {

  let configArr = getFormConfig(vm).filterFormConfig.filter(item => item.tabGroup && isCanShowBlock('dataInfo',item.showBlock));

  let thead = [], common = [];

  configArr.forEach(item => {
      if (!thead.includes(item.tabGroup)) {
          thead.push(item.tabGroup)
          item.disabled = true;
          item.placeholder = '';
          item.tabGroupName = tab_zh[item.tabGroup]
          common.push({label: item.tabGroupName, value: item.tabGroup, tagConfigs: [item]})
      } else {
        common.forEach(res => {
              if (res.value === item.tabGroup) {
                  item.disabled = true;
                  item.placeholder = '';
                  item.tabGroupName = tab_zh[item.tabGroup]
                  res.tagConfigs.push(item)
              }
          })
      }
  })

  return {
    commmonFormConfig: common.filter(item => item.value === 'THead'),
    tagFormConfig: common.filter(item => item.value !== 'THead'),
    thead
  }
};
