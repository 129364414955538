import Api from '@api';

import { YN, PAGER, CONSIGNMENT_STATUS, CREATED_INVENTORYS } from '@constant';
import {INV_TRAN_QC_STATUS, SHIPMENT_LOCK_STATUS} from "../../../../../../constant";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

/**
 * @description 列表和筛选表单不会展示的组
 */
export const noFilterGroup = ['DemandOrderPrefix', 'OrderPrefix', 'PreadvicePrefix', 'UPIPrefix'];

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        "key": "SNAP_DATE",
        "label": "库存切片日期",
        "type": "date",
        "placeholder": "请选择库存切片日期",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CLIENT_ID",
        "label": "客户端",
        "type": "magnifier",
        "placeholder": "请选择客户端",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false,
      },
      {
        "key": "TAG_ID",
        "label": "标记 ID",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入标记 ID",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CLIENT_ID",
        "label": "客户端ID",
        "type": "magnifier",
        "placeholder": "请选择客户端ID",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "OWNER_ID",
        "label": "所有者",
        "type": "magnifier",
        "placeholder": "请选择所有者",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "SKU_ID",
        "label": "SKU",
        "type": "magnifier",
        "placeholder": "请输入SKU",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SITE_ID",
        "label": "站点ID",
        "type": "select",
        "placeholder": "请选择站点ID",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "LOCATION_ID",
        "label": "位置",
        "type": "magnifier",
        "placeholder": "请选择位置",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "_DESCRIPTION_",
        "label": "描述",
        "type": "textarea",
        tableName: "C_INVENTORY",
        "placeholder": "请输入描述",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false,
        col: 2
      },
      {
        "key": "PICK_FACE",
        "label": "拣选面",
        "type": "select",
        "placeholder": "请选择拣选面",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "CONFIG_ID",
        "label": "打包配置",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入打包配置",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ORIGIN_ID",
        "label": "来源",
        "type": "magnifier",
        "placeholder": "请输入来源",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "FROM_SITE_ID",
        "label": "始发站点 ID",
        "type": "select",
        "placeholder": "请选择始发站点 ID",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "FULL_PALLET",
        "label": "全部托盘",
        "type": "select",
        "placeholder": "请选择全部托盘",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "CONDITION_ID",
        "label": "条件",
        "type": "magnifier",
        "placeholder": "请输入条件",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TO_SITE_ID",
        "label": "到达站点 ID",
        "type": "select",
        "placeholder": "请选择到达站点 ID",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "COUNT_NEEDED",
        "label": "需要的统计",
        "type": "select",
        "placeholder": "请选择需要的统计",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "LOCK_STATUS",
        "label": "状态",
        "type": "select",
        "placeholder": "请选择状态",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": SHIPMENT_LOCK_STATUS,
        "formatter": ({cellValue, row, column}) => {
          let res = SHIPMENT_LOCK_STATUS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "ZONE_1",
        "label": "库区",
        "type": "autoComplete",
        "tableName": "C_INVENTORY",
        "placeholder": "请输入库区",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "EXPIRED",
        "label": "过期",
        "type": "select",
        "placeholder": "请选择过期",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "LOCK_CODE",
        "label": "锁定代码",
        "type": "magnifier",
        "placeholder": "请输入锁定代码",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "QTY_ON_HAND",
        "label": "总数",
        "type": "numrange",
        "placeholder": "请选择总数",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_RECEIVED",
        "label": "预接受",
        "type": "select",
        "placeholder": "请选择预接受",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "DISALLOW_ALLOC",
        "label": "不接受分配",
        "type": "select",
        "placeholder": "请选择不接受分配",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "QTY_ALLOCATED",
        "label": "分配数量",
        "type": "numrange",
        "placeholder": "请选择分配数量",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入注释",
        "tabGroup": "mqs_inventory_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "BATCH_ID",
        "label": "批号",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入批号",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "QC_STATUS",
        "label": "质检状态",
        "type": "select",
        "placeholder": "请选择质检状态",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": INV_TRAN_QC_STATUS
      },
      {
        "key": "PALLET_CONFIG",
        "label": "托盘类型",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入托盘类型",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PALLET_ID",
        "label": "托盘",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入托盘",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CONTAINER_ID",
        "label": "容器",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入容器",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "RECEIPT_ID",
        "label": "接收ID",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入接收ID",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LINE_ID",
        "label": "行ID",
        "type": "numrange",
        "placeholder": "请选择行ID",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "RECEIPT_DSTAMP",
        "label": "接收日期",
        "type": "date",
        "placeholder": "请选择接收日期",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "RECEIPT_TIME",
        "label": "接收时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择接收时间",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "SUPPLIER_ID",
        "label": "供应商",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入供应商",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "MOVE_DSTAMP",
        "label": "移动日期",
        "type": "date",
        "placeholder": "请选择移动日期",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "MOVE_TIME",
        "label": "移动时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择移动时间",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "SAMPLING_TYPE",
        "label": "取样类型",
        "type": "select",
        "placeholder": "请选择取样类型",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": []
      },
      {
        "key": "MANUF_DSTAMP",
        "label": "生产日期",
        "type": "date",
        "placeholder": "请输入生产日期",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "MANUF_TIME",
        "label": "生产时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请输入生产时间",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "SPEC_CODE",
        "label": "规格代码",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入规格代码",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "COUNT_DSTAMP",
        "label": "统计日期",
        "type": "date",
        "placeholder": "请选择统计日期",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "COUNT_TIME",
        "label": "统计时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择统计时间",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "EXPIRY_DSTAMP",
        "label": "失效日期",
        "type": "date",
        "placeholder": "请输入失效日期",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "EXPIRY_TIME",
        "label": "期满时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请输入期满时间",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "BEAM_ID",
        "label": "衡梁 ID",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入衡梁 ID",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "BEAM_START_UNIT",
        "label": "衡梁开始单元",
        "type": "numrange",
        "placeholder": "请选择衡梁开始单元",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "BEAM_END_UNIT",
        "label": "衡梁结束单元",
        "type": "numrange",
        "placeholder": "请选择衡梁结束单元",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATED_INVENTORY",
        "label": "库存创建者",
        "type": "select",
        "tabGroup": "mqs_inventory_tab1",
        "tableName": "杂项",
        "placeholder": "请选择库存创建者",
        "showBlock": [
          "all"
        ],
        "options": CREATED_INVENTORYS,
        "formatter": ({ cellValue, row, column }) => {
          let res = CREATED_INVENTORYS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "BREAKPACK_DONE",
        "label": "暂停包装完成",
        "type": "select",
        "placeholder": "请选择暂停包装完成",
        "tabGroup": "mqs_inventory_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_TYPE_1",
        "label": "客户ID",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入客户ID",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_2",
        "label": "产地",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入产地",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_3",
        "label": "客户订单号",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入客户订单号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_4",
        "label": "ETQ客户单号",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入ETQ客户单号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_5",
        "label": "工厂号",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入工厂号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_6",
        "label": "集装箱号",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入集装箱号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_7",
        "label": "虚拟库位号",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入虚拟库位号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_8",
        "label": "报检号",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入报检号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_CHK_1",
        "label": "用户自定义的检查1 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查1 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_2",
        "label": "用户自定义的检查2 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查2 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_3",
        "label": "用户自定义的检查3 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查3 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_4",
        "label": "用户自定义的检查4 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查4 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_DATE_1",
        "label": "订单生产日期",
        "type": "date",
        "placeholder": "请选择订单生产日期",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_1",
        "label": "用户自定义时间 1 ",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 1 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_2",
        "label": "用户自定义日期 2 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 2 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_2",
        "label": "用户自定义时间 2 ",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 2",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_3",
        "label": "用户自定义日期 3 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 3 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_3",
        "label": "用户自定义时间 3",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 3",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_4",
        "label": "用户自定义日期 4 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 4 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_4",
        "label": "用户自定义时间 4",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 4",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_1",
        "label": "用户自定义号码 1 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 1 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_2",
        "label": "用户自定义号码 2",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 2",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_3",
        "label": "整改标记",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "整改标记",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_4",
        "label": "用户自定义号码 4 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 4 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NOTE_1",
        "label": "备注",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "备注",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NOTE_2",
        "label": "产品组",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "产品组",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      },
      {
        "key": "AB_LAST_TRANS_DSTAMP",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_AVAIL_STATUS",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_CONSIGNMENT_ID",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_COO",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_CWC",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_DOCUMENT_DSTAMP",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_DUTY_STAMP",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_INVOICE_NUMBER",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_ORIGINATOR",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_ORIGINATOR_REFERENCE",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_RECEIPT_TYPE",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_ROTATION_ID",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_UCR",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CE_UNDER_BOND",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "ENTERED_HEIGHT",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "KEY",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "NEXT_SAMPLING_ACTION",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "OUTER",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "PALLET_DEPTH",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "PALLET_HEIGHT",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "PALLET_VOLUME",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "PALLET_WEIGHT",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "PALLET_WIDTH",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "RECEIPT_TYPE",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "SAMPLED",
        "label": "",
        "type": "autoComplete",
        tableName: "C_INVENTORY",
        "placeholder": "请输入",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
    ]
  }
}