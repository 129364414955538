export const tab_en = {
  "THead": "THead",
  "mqs_inventory_tab0": "mqs_inventory_tab0",
  "mqs_inventory_tab1": "mqs_inventory_tab1",
  "mqs_inventory_tab30": "User mqs_inventory_tab30"
};

export const tab_zh = {
  "THead": "表头",
  "mqs_inventory_tab0": "综合",
  "mqs_inventory_tab1": "杂项",
  "mqs_inventory_tab30": "用户自定义的_设置1"
} 